import React from "react";
import { Outlet, Link } from "react-router-dom";


function Footer(){
    return(
        <>
            <footer className="footer_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="list-touch">
                                <div className="footer_title">
                                    <h4>Powered By</h4>
                                </div>
                                <div className="footer_logo">
                                    <img src="img/logo.png" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="list-touch">
                                <div className="footer_title">
                                    <h4>Resources</h4>
                                </div>
                                <div className="footer_list">
                                    <ul>
                                        <li>WOOFi Brokers</li>
                                        <li>Docs</li>
                                        <li>Audit</li>
                                        <li>Terms of Use</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="list-touch">
                                <div className="footer_title">
                                    <h4>Links</h4>
                                </div>
                                <div className="footer_list">
                                    <ul>
                                        <li>DeliLLama</li>
                                        <li>WOOFi NFT</li>
                                        <li>Github</li>
                                        <li>Immunefi Bug Bounty</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="list-touch">
                                <div className="footer_title">
                                    <h4>Social Media</h4>
                                </div>
                                <div className="social_list">
                                    <ul>
                                        <li><i className="fa fa-twitter"></i></li>
                                        <li><i className="fa fa-facebook"></i></li>
                                        <li><i className="fa fa-linkedin"></i></li>
                                        <li><i className="fa fa-instagram"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>    

                    </div>
                </div>
            </footer>
        </>
    )
};

export default Footer;