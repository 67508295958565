import React from "react";
function Addliquidity(){
return(
<>
<div className="container">
   <div className="col-md-8 offset-md-2">
      <div className="card p-0">
         <div className="card-body p-0">
            <div className="border-bottom p-3">
               <div className="row align-items-center">
                  <div className="col-md-6">
                     <div className="d-flex align-items-center text-white">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        <h6 className="ms-3  ">Add V3 Liquidity</h6>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="apr float-end text-white">
                        {/* 
                        <p>APR</p>
                        */}
                        <ul className="ms-0 list-unstyled d-flex">
                           {/* 
                           <li>0%</li>
                           <li><i className="fa fa-calculator" aria-hidden="true"></i></li>
                           <li><i className="fa fa-question-circle-o" aria-hidden="true"></i></li>
                           */}
                           <li><i className="fa fa-cog" aria-hidden="true" data-bs-toggle="modal" data-bs-target="#staticBackdrop3"></i></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <div className="p-4">
               <div className="row">
                  <div className="col-md-6">
                     <div className="deposit_bx">
                        <h6>CHOOSE TOKEN PAIR</h6>
                        <div className="row align-items-center my-4">
                           <div className="col-md-5">
                              <div className="c_t_p" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                 <a href="javascript:void(0);"><img src="/img/bnb.png" alt="bnb"  className="img-fluid" />BNB</a>
                                 <i className="fa fa-sort-desc" aria-hidden="true"></i>
                              </div>
                           </div>
                           <div className="col-md-2">
                              <div className="c_lus text-center">
                                 <h4>+</h4>
                              </div>
                           </div>
                           <div className="col-md-5">
                              <div className="c_t_p">
                                 <a href="javascript:void(0);"><img src="img/tether.png" alt="bnb" className="img-fluid" />BNB</a>
                              </div>
                           </div>
                        </div>
                        <h6>DEPOST AMOUNT</h6>
                        <div className="d-flex deposit-img align-items-center mt-4">
                           <img src="img/bnb.png" alt="bnb" className="img-fluid" />
                           <h6 className="ms-2">BNB</h6>
                        </div>
                        <form action="">
                           <div className="form-group mt-2 deposit_a">
                              <input name="text" id="" className="form-control " placeholder="0.0" />
                              <p>0.000000</p>
                           </div>
                        </form>
                        <div className="d-flex deposit-img align-items-center mt-4">
                           <img src="img/tether.png" alt="bnb" className="img-fluid" />
                           <h6 className="ms-2">BTCB</h6>
                        </div>
                        <form action="">
                           <div className="form-group mt-2 deposit_a">
                              <input name="text" id="" className="form-control " placeholder="0.0" />
                              <p>0.000000</p>
                           </div>
                        </form>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="liqui_box">
                        <div className="liqui_img">
                           <h2>Your position will appear here.</h2>
                           <img
                              src="https://cdn.pancakeswap.com/wallets/wallet_intro.png"
                              alt="wallet yet"
                              />
                           <br />
                           <a
                              href="javascript:void(0);"
                              className="btn btn-primary mt-3 px-4"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal3"
                              >
                           Connect Wallet
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
   <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
         <div className="modal-header border-0">
            <h5 className="modal-title text-white" id="staticBackdropLabel">Select a Token</h5>
            <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body ">
            <h6 className="card-title font-700">Common bases< img src="img/question.png" alt="question" className="img-fluid ms-2 qu" /></h6>
            <div className="swap_modal">
               <ul className="d-flex ms-0 align-items-center list-unstyled mt-3 comon_b">
                  <li>
                     <div><img src="/img/bnb.png" alt="bnb" /> BNB</div>
                  </li>
                  <li>
                     <div><img src="/img/bnb.png" alt="bnb" /> USDT</div>
                  </li>
                  <li>
                     <div><img src="/img/bnb.png" alt="bnb" /> BTCB</div>
                  </li>
                  <li>
                     <div><img src="/img/bnb.png" alt="bnb" /> ACH</div>
                  </li>
               </ul>
            </div>
            <div className="v_list">
               <ul>
                  <li>
                     <img src="/img/bnb.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>BNB</h6>
                        <p>Binance Chain Native Token</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/8pay.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>8PAY</h6>
                        <p>8PAY Network</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/ach.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>ACH</h6>
                        <p>Alchemy Token</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/ada.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>ADA</h6>
                        <p>Cardano Token</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/adx.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>ADX</h6>
                        <p>AdEx Network</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/eur.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>agEUR</h6>
                        <p>agEUR</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/alice.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>ALICE</h6>
                        <p>My Neigbor Alice</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/adx.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>ADX</h6>
                        <p>AdEx Network</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/eur.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>agEUR</h6>
                        <p>agEUR</p>
                     </div>
                  </li>
                  <li>
                     <img src="/img/alice.png" alt="bnb" className="img-fluid" />
                     <div>
                        <h6>ALICE</h6>
                        <p>My Neigbor Alice</p>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
         <div className="modal-footer border-0">
            <button type="button" className="btn btn-primary w-100">Manage Tokens</button>
         </div>
      </div>
   </div>
</div>
<div
   className="modal fade"
   id="staticBackdrop3"
   data-bs-backdrop="static"
   data-bs-keyboard="false"
   tabIndex="-1"
   aria-labelledby="staticBackdropLabel"
   aria-hidden="true"
   >
   <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
         <div className="modal-header border-0">
            <h5 className="modal-title text-white" id="staticBackdropLabel">
               Settings
            </h5>
            <button
               type="button"
               className="btn-close text-white"
               data-bs-dismiss="modal"
               aria-label="Close"
               ></button>
         </div>
         <div className="modal-body ">
            <div>
               <h4 className="bOHOqq">Swaps & Liquidity</h4>
               <div className="default_box d-flex align-items-center">
                  <p>Default Transaction Speed (GWEI)</p>
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
               </div>
               <ul
                  className="nav nav-tabs transaction border-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                  >
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        >
                     Default
                     </button>
                  </li>
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        >
                     Standard
                     </button>
                  </li>
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        >
                     Fast (6)
                     </button>
                  </li>
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link"
                        id="contact-tab1"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        >
                     Instant (7)
                     </button>
                  </li>
               </ul>
            </div>
            <div>
               <div className="default_box d-flex align-items-center">
                  <p>Slippage Tolerance</p>
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
               </div>
               <ul
                  className="nav nav-tabs transaction border-0"
                  id="myTab"
                  role="tablist"
                  >
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        >
                     0.1%
                     </button>
                  </li>
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        >
                     0.5%
                     </button>
                  </li>
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        >
                     1.0%
                     </button>
                  </li>
                  <li className="nav-item" role="presentation">
                     <button
                        className="nav-link"
                        id="contact-tab1"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        >
                     0.50%
                     </button>
                  </li>
               </ul>
            </div>
            <div>
               <table className="table table_liqui">
                  <tbody>
                     <tr className="d-flex justify-content-between align-items-center">
                        <td>
                           <div className="default_box d-flex align-items-center">
                              <p>Tx deadline (mins)</p>
                              <i
                                 className="fa fa-question-circle-o"
                                 data-bs-toggle="tooltip"
                                 data-bs-placement="top"
                                 title="Your transaction will revert if it is left confirming for longer than this time."
                                 ></i>
                           </div>
                        </td>
                        <td>
                           <div className="liqui_input">
                              <input type="text" className="iSRZGa" placeholder="35" />
                           </div>
                        </td>
                     </tr>
                     <tr className="d-flex justify-content-between align-items-center">
                        <td>
                           <div className="default_box d-flex align-items-center">
                              <p>Expert Mode</p>
                              <i
                                 className="fa fa-question-circle-o"
                                 aria-hidden="true"
                                 data-bs-toggle="tooltip"
                                 data-bs-placement="top"
                                 title="Your transaction will revert if it is left confirming for longer than this time."
                                 ></i>
                           </div>
                        </td>
                        <td>
                           <div className="content1">
                              <label className="switchSmall2 m5">
                              <input type="checkbox" />
                              <small></small>
                              </label>
                           </div>
                        </td>
                     </tr>
                     <tr className="d-flex justify-content-between align-items-center">
                        <td>
                           <div className="default_box d-flex align-items-center">
                              <p>Flippy sounds</p>
                              <i
                                 className="fa fa-question-circle-o"
                                 data-bs-toggle="tooltip"
                                 data-bs-placement="top"
                                 title="Your transaction will revert if it is left confirming for longer than this time."
                                 aria-hidden="true"
                                 ></i>
                           </div>
                        </td>
                        <td>
                           <div className="content1">
                              <label className="switchSmall2 m5">
                              <input type="checkbox" />
                              <small></small>
                              </label>
                           </div>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default Addliquidity;