import React from "react";
// import { ReactDOM } from "react";
// import listarray from "../Liquidity/LIstArray";
function Home(props) {
  return (
    <>
      <div className="main_box">
        <div className="container">
          <div className="row">
            <h1 className="main-heading">Smart Liquidity, Max Efficiency.</h1>
            <div className="col-md-6 offset-md-3">
              <div className="card">
                <div className="card-body">
                  <div className="card_heading d-flex justify-content-between align-items-center">
                    <h5 className="card-title" onClick={props.dynamicpairapi}>
                      Swap
                    </h5>

                    <div className="swap_box_icon">
                      {/* <i className="fa fa-circle-o"></i> */}
                      {/* <img
                        src="img/bars.png"
                        className="img-fluid pointer"
                        alt="bars"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      /> */}
                    </div>
                  </div>
                  <div className="swap_box">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="card_heading d-flex justify-content-between align-items-center"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop3"
                        >
                          {" "}
                          <h5 className="card-title">
                            <img
                              src={props.path}
                              alt="bnb"
                              className="img-fluid"
                            />{" "}
                            {props.name}
                            <i
                              className="fa fa-sort-desc"
                              aria-hidden="true"
                            ></i>
                          </h5>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <select name="">
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                          <option value="4">Four</option>
                        </select>
                      </div> */}
                    </div>
                    {/* 
                     <div className="card_heading d-flex justify-content-between align-items-center">
                        <h5 className="card-title"><img src="img/bnb.png" className="img-fluid" /> BNB Chain</h5>
                        <div className="swap_box_icon">
                           < img src="img/tether.png" className="img-fluid" /> 
                           <h5 className="card-title">BTCB</h5>
                        </div>
                     </div>
                     */}
                    <div className="bnb_chain_i">
                      <div className="row align-itmes-center">
                        <div className="col-md-6">
                          <input
                            className="form-control bg-0 border-0"
                            type="text"
                            placeholder="0"
                            value={props.inputvalue1}
                            onChange={props.inputvaluechange1}
                          />
                          {/* USD {props.usd_value1} */}
                        </div>
                        <div className="col-md-3">
                          <img
                            src="img/dark-wallet.png"
                            alt="dark-wallet"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="text-center">--</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swap_arrow_icon text-center">
                    <img
                      src="img/arrow.png"
                      className="img-fluid"
                      // onClick={props.arrowswap}
                    />
                  </div>
                  <div className="swap_box mt-0">
                    <div
                      className="card_heading d-flex justify-content-between align-items-center"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop4"
                    >
                      <h5 className="card-title">
                        <img
                          src={props.path1}
                          alt="bnb chain"
                          className="img-fluid"
                        />{" "}
                        {props.name1}
                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                      </h5>
                      {/* <div className="swap_box_icon">
                        <img src="img/tether.png" className="img-fluid" />
                        <h5 className="card-title">BTCB</h5>
                      </div> */}
                    </div>
                    <div className="bnb_chain_i">
                      <div className="row align-itmes-center">
                        <div className="col-md-6">
                          <input
                            className="form-control bg-0 border-0"
                            type="text"
                            // placeholder={props.inputvalue2}
                            placeholder="0"
                            value={props.inputvalue2}
                            onChange={props.inputvaluechange2}
                          />
                          {/* USD {props.usd_value2} */}
                        </div>
                        <div className="col-md-3">
                          <img
                            src="img/dark-wallet.png"
                            alt="dark wallet"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="text-center">--</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card_heading d-flex justify-content-between align-items-center my-4">
                    <h5 className="card-title">
                      {/* Slippage Tolerance <i className="fa fa-edit ms-2"></i> */}
                    </h5>
                    <div className="swap_box_icon">
                      {/* <h5 className="card-title">2%</h5> */}
                    </div>
                  </div>

                  {  props.swapbtn ? (
                    <>
                      <div className="pricing">
                        <ul>
                          <li>Price</li>
                          <li>1 {props.pricetokenname1}</li>
                          <li>
                            <img
                              src="img/swap.png"
                              className="img-fluid"
                              onClick={props.pricearrowswap}
                            />
                          </li>
                          <li>
                            {props.priceinput1} {props.pricetokenname2}
                          </li>
                          <li>
                            <img
                              src="img/refresh.png"
                              className="img-fluid"
                              onClick={props.pricearrowswap}
                            />
                          </li>
                        </ul>
                      </div>
                     { props.enteramountbtn === "Connect Wallet"? 


                    <div className="wallet_btn">
                      <button
                        className="btn btn-primary w-100 py-3 bg-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal3"
                      >
                        {/* {props.enteramountbtn} */}
                        Connect  Wallet
                      </button>
                    </div>
                    : 
                    <div className="wallet_btn">
                    <button
                      className="btn btn-primary w-100 py-3 bg-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop2"
                    >
                      {/* {props.enteramountbtn} */}
                      Swap
                    </button>
                  </div>
}
                      <table className="table table_liqui">
                        <tbody>
                          <tr className="d-flex justify-content-between align-items-center">
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>{props.minimumreceivename}</p>
                                <i
                                  className="fa fa-question-circle-o"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Your transaction will revert if it is left confirming for longer than this time."
                                ></i>
                              </div>
                            </td>
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>
                                  {props.minimumreceive}{" "}
                                  {props.minimumReceiveLastTokenName}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr className="d-flex justify-content-between align-items-center">
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>Price Impact</p>
                                <i
                                  className="fa fa-question-circle-o"
                                  aria-hidden="true"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Your transaction will revert if it is left confirming for longer than this time."
                                ></i>
                              </div>
                            </td>
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>0.07%</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="d-flex justify-content-between align-items-center">
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>Trading Fee</p>

                                <i
                                  className="fa fa-question-circle-o"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Your transaction will revert if it is left confirming for longer than this time."
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </td>
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>0.0025 {props.name}</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="d-flex justify-content-between align-items-center">
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>Route</p>
                                <i
                                  className="fa fa-question-circle-o"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Your transaction will revert if it is left confirming for longer than this time."
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </td>
                            <td>
                              <div className="s_box d-flex align-items-center">
                                <p>
                                  {props.name} - {props.name1}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) :
                  
        
                  props.enteramountbtn === "Connect Wallet" ? (
                   
                    props.enteramountbtn === "Connect Wallet"? 
                    <div className="wallet_btn">
                      <button
                        className="btn btn-primary w-100 py-3 bg-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal3"
                      >
                        {/* {props.enteramountbtn} */}
                        Connect  Wallet
                      </button>
                    </div>
                    : 
                    <div className="wallet_btn">
                    <button
                      className="btn btn-primary w-100 py-3 bg-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop2"
                    >
                      {/* {props.enteramountbtn} */}
                      Swap
                    </button>
                  </div>
                  
                    
                  )
                 
                  :
                  (
                    <div className="bnb_chain_i">
                      <input
                        className="form-control bg-0 border-0"
                        type="text"
                        placeholder="Enter an amount"
                        disabled
                      />
                    </div>
                  )}


                </div>
              </div>
            </div>
            <div className="col-md-7 d-none">
              <div className="card">
                <div className="card-body">
                  <div className="card_heading d-flex justify-content-between">
                    <h5 className="card-title">Swap</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      Card subtitle
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="enter_amount d-flex align-items-center justify-content-center">
                    <img
                      src="img/380d82e.png"
                      alt="document"
                      className="img-fluid"
                    />
                    <p>Enter amounts to get quotes and compare prices.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-white pb-5">
                          My History
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div className="history_box py-5">
                    <img
                      src="img/history.png"
                      alt="history"
                      className="img-fluid"
                    />
                    <h6>No History</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Settings
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <h6 className="card-title font-700">
                {/* Slippage tolerance{" "} */}
                <img
                  src="img/question.png"
                  alt="question"
                  className="img-fluid ms-2 qu"
                />
              </h6>
              <div className="swap_modal">
                <ul className="d-flex ms-0 align-items-center list-unstyled mt-3">
                  <li>
                    <div>0.5%</div>
                  </li>
                  <li>
                    <div>1%</div>
                  </li>
                  <li>
                    <div>2%</div>
                  </li>
                  <li>
                    <input type="text" className="form-control" />
                  </li>
                  {/* 
                  <li>%</li>
                  */}
                </ul>
              </div>
              <h6 className="card-title font-700 mt-5">
                Transaction Deadline{" "}
                <img
                  src="img/question.png"
                  alt="question"
                  className="img-fluid ms-2 qu"
                />
              </h6>
              <div className="swap_modal">
                <ul className="d-flex ms-0 list-unstyled align-items-center mt-3">
                  <li>
                    <div>30</div>
                  </li>
                  <li>Minutes</li>
                </ul>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button type="button" className="btn btn-primary w-100">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* End Modal */}

      <div
        className="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Confirm Swap
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <table className="table table_liqui">
                <tbody>
                  <tr className="d-flex justify-content-between align-items-center">
                    <td>
                      <div className="confirm_box d-flex align-items-center">
                        <img src={props.path} className="img-fluid" />
                        <p>{props.inputvalue1}</p>
                      </div>
                    </td>
                    <td>
                      <div className="liqui_input">
                        <p>{props.name}</p>
                      </div>
                    </td>
                  </tr>
                  <tr className="d-flex justify-content-between align-items-center">
                    <td colSpan="2">
                      <div className="confirm_box d-flex align-items-center">
                        <img
                          src="img/arrow.png"
                          alt="arrow"
                          className="img-fluid"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="d-flex justify-content-between align-items-center">
                    <td>
                      <div className="confirm_box d-flex align-items-center">
                        <img src={props.path1} className="img-fluid" />
                        <p>{props.inputvalue2}</p>
                      </div>
                    </td>
                    <td>
                      <div className="liqui_input">
                        <p>{props.name1}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="card_heading my-4">
                <div className="d-flex justify-content-between align-items-center">
                  {/* <h5 className="card-title">Slippage Tolerance</h5> */}
                  <div className="swap_box_icon">
                    {/* <h5 className="card-title">0.50%</h5> */}
                  </div>
                </div>
                <p className="my-3">
                  Output is estimated. You will receive at least 5046543588 CAKE
                  or the transaction will revert.
                </p>
              </div>

              <div className="bnb_chain_i">
                <table className="table table_liqui">
                  <tbody>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>Price</p>
                        </div>
                      </td>
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>
                            {props.priceinput1} {props.name} / {props.name1}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>{props.minimumreceivename}</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>
                            {props.minimumreceive}{" "}
                            {props.minimumReceiveLastTokenName}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>Price Impact</p>
                          <i
                            className="fa fa-question-circle-o"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>0.07%</p>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>Trading Fee</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                            aria-hidden="true"
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="s_box d-flex align-items-center">
                          <p>0.0025 {props.name}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={props.handlerswap}
              >
                Confirm Swap
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop3"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Select a Token
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body ">
              <div className="mb-3 token_model">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onChange={props.handleSearch}
                />
              </div>
              <h6 className="card-title font-700">
                Common bases 
                <img
                  src="img/question.png"
                  alt="question"
                  className="img-fluid ms-2 qu"
                />
              </h6>
              <div className="swap_modal">
                <ul className="d-flex ms-0 align-items-center list-unstyled mt-3 comon_b">
                  {/* <li>
                    {props.historycurrencyname ? (
                      <>
                        <div data-bs-dismiss="modal" aria-label="Close">
                          <img src={props.historycurrencypath} alt="bnb" />{" "}
                          {props.historycurrencyname}
                        </div>
                      </>
                    ) : null}
                  </li> */}
                </ul>
              </div>
              <div className="v_list">
                <ul>
                  {props.data.length != 0 ? (
                    <>
                      {props.data.map((item1, index1) => (
                        <>
                          <li
                            key={index1}
                            onClick={() => props.handlerset(item1)}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <img
                              src={item1.logoURI}
                              alt="bnb"
                              className="img-fluid"
                            />
                            <div>
                              <h6>{item1.symbol}</h6>
                              <p>{item1.name}</p>
                            </div>
                          </li>
                        </>
                      ))}
                    </>
                  ) : props.inputvalueaddress.length == 42 ? (
                    <>
                      <li
                        onClick={() => props.handlerimportbtn1()}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="justify-content-between"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src="img/questionmark.jpg"
                            alt="bnb"
                            className="img-fluid"
                          />
                          <div>
                            <h6>{props.importtokensymbol}</h6>
                            <p>{props.importtokenname}</p>
                          </div>
                        </div>
                        <div className="text-and">
                          <button className="btn btn-primary ">Import</button>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <div>
                        <h5>Data not found</h5>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop5"
              >
                {/* onClick={props.coingeckapi} */}
                Manage Tokens:
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop4"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Select a Token
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body ">
              <div className="mb-3 token_model">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onChange={props.handleSearch}
                />
              </div>

              <h6 className="card-title font-700">
                Common bases
                <img
                  src="img/question.png"
                  alt="question"
                  className="img-fluid ms-2 qu"
                />
              </h6>
              <div className="swap_modal">
                <ul className="d-flex ms-0 align-items-center list-unstyled mt-3 comon_b">
                  <li>
                    {props.historycurrencyname ? (
                      <>
                        <div data-bs-dismiss="modal" aria-label="Close">
                          <img src={props.historycurrencypath} alt="bnb" />{" "}
                          {props.historycurrencyname}
                        </div>
                      </>
                    ) : null}
                  </li>
                  {/* <li>
                    <div>
                      <img src="/img/bnb.png" alt="bnb" /> USDT
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="/img/bnb.png" alt="bnb" /> BTCB
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="/img/bnb.png" alt="bnb" /> ACH
                    </div>
                  </li> */}
                </ul>
              </div>
              <div className="v_list">
                <ul>
                  {props.data.length != 0 ? (
                    <>
                      {props.data.map((item1, index1) => (
                        <>
                          <li
                            key={index1}
                            onClick={() => props.handlerset1(item1)}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <img
                              src={item1.logoURI}
                              alt="bnb"
                              className="img-fluid"
                            />
                            <div>
                              <h6>{item1.symbol}</h6>
                              <p>{item1.name}</p>
                            </div>
                          </li>
                        </>
                      ))}
                    </>
                  ) : props.inputvalueaddress.length == 42 ? (
                    <>
                      <li
                        onClick={() => props.handlerimportbtn2()}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="justify-content-between"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src="img/questionmark.jpg"
                            alt="bnb"
                            className="img-fluid"
                          />
                          <div>
                            <h6>{props.importtokensymbol}</h6>
                            <p>{props.importtokenname}</p>
                          </div>
                        </div>
                        <div className="text-and">
                          <button className="btn btn-primary">Import</button>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <div>
                        <h5>Data not found</h5>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop5"
              >
                Manage Tokens:
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop5"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Manage
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body ">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    List
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Tokens
                  </button>
                </li>
              </ul>
              <div className="tab-content mt-4" id="myTabContent">
                <div
                  className="tab-pane fade show active mt-2 px-3 border-radius-0"
                  style={{ borderRadius: "15px", padding: "10px" }}
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="token_model">
                    <input
                      type="text"
                      placeholder="https:// or ipfs://"
                      className="form-control"
                    />
                  </div>

                  <div>
                    <table className="table table_liqui liqui2">
                      <tbody>
                        <tr className="d-flex justify-content-between align-items-center">
                          <td>
                            <div className="manage_box d-flex align-items-center">
                              <img
                                src="img/7186.png"
                                className="img-fluid"
                                alt="bnb"
                              />
                              <div className="text-start">
                                <h6>PancakeSwap Extended</h6>{" "}
                                <span>338 Tokens</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="content1">
                              <label className="switchSmall2 m5">
                                <input
                                  type="checkbox"
                                  onClick={props.pancakeswapapi}
                                />
                                <small></small>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr className="d-flex justify-content-between align-items-center">
                          <td>
                            <div className="manage_box d-flex align-items-center">
                              <img
                                src="img/coingecko.png"
                                className="img-fluid"
                                alt="bnb"
                              />
                              <div className="text-start">
                                <h6>CoinGecko</h6>
                                <span>3999 Tokens</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="content1">
                              <label className="switchSmall2 m5">
                                <input
                                  type="checkbox"
                                  onClick={props.coingeckapi}
                                />
                                <small></small>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr className="d-flex justify-content-between align-items-center">
                          <td>
                            <div className="manage_box d-flex align-items-center">
                              <img
                                src="img/marketcoin.png"
                                className="img-fluid"
                                alt="bnb"
                              />
                              <div className="text-start">
                                <h6>CoinMarket Cap</h6>
                                <span>7924 Tokens</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="content1">
                              <label className="switchSmall2 m5">
                                <input
                                  type="checkbox"
                                  onClick={props.coinmarketapi}
                                />

                                <small></small>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr className="d-flex justify-content-between align-items-center">
                          <td>
                            <div className="manage_box d-flex align-items-center">
                              <img
                                src="img/7186.png"
                                className="img-fluid"
                                alt="bnb"
                              />
                              <div className="text-start">
                                <h6>PancakeSwap BNB Chain MM</h6>
                                <span>6 Tokens</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="content1">
                              <label className="switchSmall2 m5">
                                <input type="checkbox" />

                                <small></small>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  style={{ borderRadius: "15px", padding: "20px 15px" }}
                >
                  <div className="token_model">
                    <input
                      type="text"
                      placeholder="https:// or ipfs://"
                      className="form-control"
                    />
                    <h5 className="mt-3">0 Imported Tokens</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
