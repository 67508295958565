import React from "react";

function Stake(){
    return(
        <>
  <div className="main_box">
      <div className="container">
               <div className="row">
         <div className="col-md-12">
            <h1 className="main-heading">Stake BXP to maximize your yields</h1>
         </div>
         <div className="offset-md-2 col-md-8">
            <div className="card">
               <div className="card-body">
                    <div className="staked_heading">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="stake_left text-start">
                                    <h3>Total Staked</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right_left text-end">
                                <h3>85,034,951 BXP</h3>
                                <p>~$17,398,150.98</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="stake_list">
                        <ul className="list-unstyled">
                            <li>
                                <div className="d-flex text-white align-items-center">
                                <img src="img/727b33d.png" alt="727b33d" />
                                <h6>21.05M</h6>
                                <p>(APR 11.64%)</p>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex text-white align-items-center">
                                <img src="img/cbd0b85.png" alt="cbd0b85" />
                                <h6>21.05M</h6>
                                <p>(APR 11.64%)</p>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex text-white align-items-center">
                                <img src="img/bbc9ede.png" alt="bbc9ede" />
                                <h6>21.05M</h6>
                                <p>(APR 11.64%)</p>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex text-white align-items-center">
                                <img src="img/bnb.png" alt="bnb" />
                                <h6>21.05M</h6>
                                <p>(APR 11.64%)</p>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex text-white align-items-center">
                                <img src="img/0beb238.png" alt="0beb238" />
                                <h6>21.05M</h6>
                                <p>(APR 11.64%)</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                
                    <div className="row align-items-center">
                    <div className="col-md-12">
                    <ul className="nav nav-tabs border-0 pt-5 pb-0 justify-content-center stakeul" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">STAKE</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">UNSTAKE</button>
                        </li>
                        </ul>
                        
                    </div>

                    <div className="col-md-12">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active mt-4 stake_active" id="home" role="tabpanel" aria-labelledby="home-tab" >
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                <div className="bnb_chain_s mt-4">
                                    <div className="row align-itmes-center">
                                        <div className="col-md-6">
                                            <input className="form-control bg-0 border-0" type="text" placeholder="0 BXP" />
                                        </div>
                                        <div className="col-md-3">
                                            <img src="img/dark-wallet.png" alt="dark-wallet" className="img-fluid" />
                                        </div>
                                        <div className="col-md-3">
                                            <div className="text-center stake_--">
                                                --
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="wallet_btn">
                                        <div className="stake_text mb-2 d-flex  align-itmes-center float-end text-white">
                                            <p>7-day unstaking period</p>
                                            <i className="fa fa-question"></i>
                                        </div>
                                        <button className="btn btn-primary w-100 py-3 bg-btn">Connet Wallet</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            
                        </div>
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <div className="staked_info d-flex align-items-center">
                                    <img src="/img/icon/stake1.png" alt="stake1" className="img-fluid" />    
                                    <div className="text-white stake_imgtext">
                                        <h6>Last 7 days APR</h6>
                                        <h6>-</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="staked_info d-flex align-items-center">
                                    {/* <img src="/img/icon/stake2.png" alt="stake2" className="img-fluid" />     */}
                                    <div className="text-white stake_imgtext">
                                        <h6>Last 7 days APR</h6>
                                        <h6>-</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="staked_info d-flex align-items-center">
                                    {/* <img src="/img/icon/stake3.png" alt="stake3" className="img-fluid" />     */}
                                    <div className="text-white stake_imgtext">
                                        <h6>Last 7 days APR</h6>
                                        <h6>-</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                     <div className="col-md-12">
                        <div className="faq">
                            <p className="text-white">Stake BXP to receive BXP that represents your share of the staking pool. bidex swap fee is used to buy back BXP on a daily basis and distributed to the staking pool. When you unstake, 
                            
                            BXP will be burned to redeem the staked BXP including the rewards after the </p>
                        </div>
                    </div>   
                    
                   </div> 

               </div>
            </div>
         </div>
      </div>
   </div>
</div>
        </>
    );
}

export default Stake;