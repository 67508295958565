// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./Menu/Menu";
import Home from "./Home/Home";
import Earn from "./Earn/Earn";
import Stake from "./Stake/Stake";
import Dashboard from "./Dashboard/Dashboard";
import Liquidity from "./Liquidity/Liquidity";
import Addliquidity from "./Liquidity/Addliquidity";
import NewState from "./NewState/NewState";
import Pool from "./Pool/Pool";
import axios from "axios";

import Footer from "./Footer/Footer";

//for integration;
import Web3 from "web3";
import { useState, useEffect } from "react";

//import abi from ABI
import busdABI from "../src/Abi/BUSDabi.json";
import routercontractABI from "../src/Abi/routerTestnetABI.json";
import factoryAbi from "../src/Abi/factoryMainnetABI.json";
import pairAbi from "../src/Abi/pairMainnetABI.json";
import tokenABI from "../src/Abi/erc20MAinnetABI.json";

function App() {
  const [contract1, setContract1] = useState(null);
  const [contract2, setContract2] = useState(null);
  const [factoryContract, setFactoryContract] = useState(null);
  const [tokenContract, setTokencontract] = useState("");
  const [web3, setWeb3] = useState("");
  const [web33, setWeb33] = useState("");

  // usestate api state
  const [data, setData] = useState([]);
  // const [pairaddressdata ,setPairaddressdata]=useState([]);
  const [tokenpairaddress, seTokenpairaddress] = useState();

  const [searchTerm, setSearchTerm] = useState("");
  const [flag_pancake, setFlag_pancake] = useState(false);
  const [flag_coingecko, setFlag_coingecko] = useState(false);
  const [flag_coinmarket, setFlag_coinmarket] = useState(false);

  // for swapbutton
  const [swapbtn, setSwapbtn] = useState();

  //inputchangevalue usestate
  const [amountIn, setAmountIn] = useState(0);
  const [priceinput1, setPriceinput1] = useState(0);
  const [priceinput2, setPriceinput2] = useState(0);
  const [minimumreceive, setMinimumreceive] = useState(0);
  const [minimumreceivename, setMinimumreceivename] =
    useState("Minimum Received");
  const [minimumReceiveLastTokenName, setMinimumReceiveLastTokenName] =
    useState("");

  const [usd_value1, setUsd_value1] = useState(0);
  const [inputvalue1, setInputvalue1] = useState();

  const [inputvalue2, setInputvalue2] = useState();
  const [usd_value2, setUsd_value2] = useState(0.0);

  //swap contract address
  const RouterContractAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E"; //rounter abi address for mainnet
  const factoryAddress = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"; //factory abi address for mainnet
  const tokenAddress = "0x73e199E4f9af71Fb5973B21234a1d7a4BAA2bBf6"; //token address for testnet

  const amountOutMin = 0;
  const deadline = Math.floor(Date.now() / 1000) + 3600;

  //for menu
  const [account, setAccount] = useState("Connect Wallet");

  //for home
  const [enteramountbtn, setEnteramountbtn] = useState("Connect Wallet");

  const [name, setName] = useState("WBNB");
  const [name1, setName1] = useState("USDC");
  const [importtokenname, setImporttokenname] = useState("");
  const [importtokensymbol, setImporttokensymbol] = useState("");
  const [inputvalueaddress, setInputvalueaddress] = useState("");

  const [pricetokenname1, setPricetokenname1] = useState("WBNB");
  const [pricetokenname2, setPricetokenname2] = useState("USDC");
  const [gettokenuser, setGettokenuser] = useState("");

  const [historycurrencyname, setHistorycurrencyname] = useState(null);
  const [historycurrencypath, setHistorycurrencypath] = useState("");

  const [currencyname, setCurrencyname] = useState("8Pay");
  const [path, setPath] = useState(
    "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png"
  );
  const [path1, setPath1] = useState(
    "https://tokens.pancakeswap.finance/images/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d.png"
  );
  const [tokenaddresss1, setTokenaddress1] = useState(
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  );
  const [tokenaddresss2, setTokenaddress2] = useState(
    "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
  );
  const [pathpair, setPathpair] = useState([
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  ]);

  useEffect(() => {
    console.log("useeffect run contract wala");
    const a = async () => {
      // const web3 = new Web3(provider);
      const web3 = new Web3("https://bsc-dataseed.binance.org");
      setWeb3(web3);
      const networkId = await web3.eth.net.getId();

      console.log(`network id 1 ${networkId}`);

      //contract connection :::-----------------------
      const contract1 = new web3.eth.Contract(
        routercontractABI,
        RouterContractAddress
      );
      setContract1(contract1);
      const factoryContract = new web3.eth.Contract(factoryAbi, factoryAddress);
      setFactoryContract(factoryContract);
    };
    a();
  }, []);

  // connect wallet function ===============================================================================
  const handlerconnectmetamask = async () => {
    // let provider = window.ethereum;
    let provider = window.web3.currentProvider;
    if (typeof provider !== "undefined") {
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });

      const account = accounts[0];
      setAccount(account);
      setEnteramountbtn("enter an amount");
      console.log(`selected account is -->${account}`);
      ////
      const web3 = new Web3(provider);

      setWeb3(web3);
      const networkId = await web3.eth.net.getId();

      console.log(`network id 1 ${networkId}`);

      //contract connection :::-----------------------
      const contract1 = new web3.eth.Contract(
        routercontractABI,
        RouterContractAddress
      );
      setContract1(contract1);
      const factoryContract = new web3.eth.Contract(factoryAbi, factoryAddress);
      setFactoryContract(factoryContract);
    } else {
      console.log(
        " oops ...please download metamask first otherwise metamask will not  connect"
      );
    }
  };

  //handler for integrate  contract ===============================================================================
  const handlerswap = async () => {
    if (name === "WBNB") {
      console.log(" BNB to token");

      await contract1.methods
        .swapExactETHForTokens(amountOutMin, pathpair, account, deadline)
        .send({
          from: account,
          value: amountIn,
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (name1 === "WBNB") {
      console.log(" token to bnb");

      const busdInstance = new web3.eth.Contract(busdABI, tokenaddresss1);
      setContract2(busdInstance);

      await contract2.methods
        .approve(RouterContractAddress, amountIn)
        .send({
          from: account,
          // gasPrice: "5000000000",  //in gwei
          // gasLimit: "200000",
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });

      await contract1.methods
        .swapExactTokensForETH(
          amountIn,
          amountOutMin,
          pathpair,
          account,
          deadline
        )
        .send({
          from: account,
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("token to token");
      const busdInstance = new web3.eth.Contract(busdABI, tokenaddresss1);
      setContract2(busdInstance);

      await contract2.methods
        .approve(RouterContractAddress, amountIn)
        .send({
          from: account,
          // gasPrice: "5000000000",  //in gwei
          // gasLimit: "200000",
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });

      await contract1.methods
        .swapExactTokensForTokens(
          amountIn,
          amountOutMin,
          // [tokenaddresss1, tokenaddresss2],
          pathpair,
          account,
          deadline
        )
        .send({
          from: account,
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });

      // const return_amount = await contract1.methods.swapExactTokensForTokens(amountIn, amountOutMin, path_coins, account, deadline).call();

      // console.log("return amount",return_amount);
    }
  };

  //handler for select token1 from first  input field:===============================================================================
  const handlerset = (item1) => {
    setName(item1.symbol);
    setPricetokenname1(item1.symbol);
    setPath(item1.logoURI);
    setTokenaddress1(item1.address);
    console.log(item1.address);
    setHistorycurrencyname(item1.symbol);
    setHistorycurrencypath(item1.logoURI);
  };

  //handler for select token2 from second  input field:===============================================================================
  const handlerset1 = (item) => {
    setName1(item.symbol);
    setPricetokenname2(item.symbol);
    setPath1(item.logoURI);
    setCurrencyname(item.Tname);
    setTokenaddress2(item.address);
    console.log(item.address);
    setHistorycurrencyname(item.symbol);
    setHistorycurrencypath(item.logoURI);
  };
  if (name === name1) {
    setName1("select ");
    setCurrencyname("select a currency");
  }

  //handler for show/hide swap button onclick on first  input field=========================================================================
  const inputvaluechange1 = (e) => {
    if (e.target.value) {
      setSwapbtn(true);
      if (enteramountbtn === "enter an amount") {
        setEnteramountbtn("Swap");
      }
    } else {
      setSwapbtn(false);
      setInputvalue2(0);
    }
    setMinimumreceivename("Minimum Received");
    setMinimumReceiveLastTokenName(name1);

    setUsd_value1(e.target.value * 332.42);
    setUsd_value2(e.target.value * 0.0107867);
    setInputvalue1(e.target.value);

    //  getamount and  function
    if (e.target.value > 0) {
      console.log("run first input condition");
      const getamount = async () => {
        let myString = e.target.value.toString();
        // let myString=e.target.value;
        const amountIn = web3.utils.toWei(myString, "ether");
        setAmountIn(amountIn);

        //call getamountout function from contract
        const amountOut = await contract1.methods
          .getAmountsOut(amountIn, pathpair)
          .call();

        const outputAmountget = amountOut[amountOut.length - 1];
        console.log(outputAmountget);

        // console.log(" amountOut[0] " + amountOut[0]);
        // console.log(" amountOut[1] " + amountOut[1]);
        console.log("outputAmount in inputfield 2  --> " + outputAmountget);

        const receivedBNB = web3.utils.fromWei(outputAmountget, "ether");
        console.log(
          "amountOut[1] converted into  fromWei  -->  " + receivedBNB
        );

        const myNumber = receivedBNB;
        // const fourdigit = Math.round(myNumber * 10000) / 10000; //show til four digit
        const fourdigit = myNumber;
        setInputvalue2(fourdigit);
        setMinimumreceive(fourdigit);
      };
      getamount();

      // integration for factory contract------------------------------------------------------------------------------------
      async function gettokentotokenPrice() {
        const pair = await factoryContract.methods
          .getPair(tokenaddresss1, tokenaddresss2)
          .call();
        const pairContract = new web3.eth.Contract(pairAbi, pair);

        // Get the reserve amounts:
        const reserves = await pairContract.methods.getReserves().call();
        const reserve0 = reserves[0];
        const reserve1 = reserves[1];

        // Calculate the price of 1 token in terms of token2
        const priceCAKEtoBUSD = reserve1 / reserve0;
        const priceBUSDtoCAKE = reserve0 / reserve1;
        const pricetoken1totoken2 = Number(priceCAKEtoBUSD.toFixed(5));
        const pricetoken2totoken1 = Number(priceBUSDtoCAKE.toFixed(5));
        setPriceinput1(pricetoken1totoken2);
        setPriceinput2(pricetoken2totoken1);
        console.log(`1 token1 = ${priceCAKEtoBUSD} token2`);
        console.log(`1 token2 = ${priceBUSDtoCAKE} token1`);
      }

      gettokentotokenPrice();
    }
  };

  //this is for second input ===============================================================================
  const inputvaluechange2 = (e) => {
    if (e.target.value) {
      setSwapbtn(true);
    } else {
      setSwapbtn(false);
      setInputvalue1(0);
    }
    setMinimumreceivename("Maximum sold");
    setMinimumReceiveLastTokenName(name);
    setUsd_value1(e.target.value * 0.0107867);
    setUsd_value2(e.target.value * 332.42);
    setInputvalue2(e.target.value);

    if (e.target.value > 0) {
      const getamount = async () => {
        let myString = e.target.value.toString();
        console.log("string amount 2" + myString);
        const amount_out_wei = web3.utils.toWei(myString, "ether");

        setAmountIn(amount_out_wei);

        //getamountIn from contract1
        const GetAmountIn = await contract1.methods
          .getAmountsIn(amount_out_wei, pathpair)
          .call();

        // const inputAmountget = GetAmountIn[GetAmountIn.length - 1]
        console.log("input amount get input field2  " + GetAmountIn[0]);

        console.log(" GetAmountIn[0]  " + GetAmountIn[0]);
        console.log(" GetAmountIn[1]  " + GetAmountIn[1]);

        const receivedBNB = web3.utils.fromWei(GetAmountIn[0], "ether");
        console.log(
          " GetAmountIn[0] converted into fromWei method -->" + receivedBNB
        );
        const myNumber = receivedBNB;
        // const fourdigit = Math.round(myNumber * 10000) / 10000; //show til four digit
        const fourdigit = myNumber;
        setInputvalue1(fourdigit);
        setMinimumreceive(fourdigit);
      };
      getamount();

      // integration for factory contract------------------------------------------------------------------------------------
      async function gettokentotokenPrice() {
        const pair = await factoryContract.methods
          .getPair(tokenaddresss1, tokenaddresss2)
          .call();

        const pairContract = new web3.eth.Contract(pairAbi, pair);

        // Get the reserve amounts
        const reserves = await pairContract.methods.getReserves().call();
        const reserve0 = reserves[0];
        const reserve1 = reserves[1];

        // Calculate the price of 1 token in terms of token2
        const priceCAKEtoBUSD = reserve1 / reserve0;
        const priceBUSDtoCAKE = reserve0 / reserve1;
        const pricetoken1totoken2 = Number(priceCAKEtoBUSD.toFixed(5));
        const pricetoken2totoken1 = Number(priceBUSDtoCAKE.toFixed(5));

        setPriceinput1(pricetoken2totoken1);
        setPriceinput2(pricetoken1totoken2);
        console.log(`1 token1- = ${priceCAKEtoBUSD} token2`);
        console.log(`1 token2 = ${priceBUSDtoCAKE} token1`);
      }

      gettokentotokenPrice();
    }
  };

  //arrow swap function ====================================================================================================================
  const arrowswap = () => {
    setName1(name);
    setName(name1);
    setPath(path1);
    setPath1(path);
    setInputvalue1(inputvalue2);
    setInputvalue2(inputvalue1);
    setUsd_value1(usd_value2);
    setUsd_value2(usd_value1);

    setPriceinput1(priceinput2);
    setPriceinput2(priceinput1);
    setPricetokenname1(pricetokenname2);
    setPricetokenname2(pricetokenname1);

    // setTokenaddress1(tokenaddresss1);
    // setTokenaddress2(tokenaddresss2);
    // inputvaluechange1();
  };

  //price arrow swap function ====================================================================================================================
  const pricearrowswap = () => {
    setPriceinput1(priceinput2);
    setPriceinput2(priceinput1);
    setPricetokenname1(pricetokenname2);
    setPricetokenname2(pricetokenname1);
  };

  //useeffect for fetch api on manage token button ===============================================================================
  useEffect(() => {
    const fetchData = async () => {
      try {
        var url;
        if (flag_pancake) {
          url = "https://tokens.pancakeswap.finance/pancakeswap-extended.json";
        } else if (flag_coingecko) {
          url = "https://tokens.pancakeswap.finance/coingecko.json";
        } else if (flag_coinmarket) {
          url = "https://tokens.pancakeswap.finance/cmc.json";
        } else {
          url = "https://tokens.pancakeswap.finance/pancakeswap-bnb-mm.json";
        }

        const response = await fetch(url);
        const jsonData = await response.json();
        setData(jsonData.tokens);
        console.log(jsonData.tokens);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [flag_pancake, flag_coingecko, flag_coinmarket]);

  const coingeckapi = () => {
    setFlag_coingecko(!flag_coingecko);
  };
  const pancakeswapapi = () => {
    setFlag_pancake(!flag_pancake);
  };
  const coinmarketapi = () => {
    console.log("coinmarketcap");
    setFlag_coinmarket(!flag_coinmarket);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setInputvalueaddress(event.target.value);
  };

  //filter function for search api===============================================================================
  const filteredData = data.filter(
    (item) =>
      item.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (inputvalueaddress >= 42) {
    const handlercontract = async () => {
      try {
        // const contractAddress ='0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee';
        // 0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee
        // 0x64544969ed7EBf5f083679233325356EbE738930
        const web33 = new Web3("https://bsc-dataseed.binance.org");
        const tokenContract = new web33.eth.Contract(
          tokenABI,
          inputvalueaddress
        );
        //  setTokencontract(tokenContract)
        const name = await tokenContract.methods.name().call();
        const symbol = await tokenContract.methods.symbol().call();
        const decimals = await tokenContract.methods.decimals().call();

        console.log("Token Name:", name);
        console.log("Token Symbol:", symbol);
        console.log("Token Decimals:", decimals);
        setImporttokenname(name);
        setImporttokensymbol(symbol);

        // setImporttokenname(name);
      } catch (error) {
        console.error("Error retrieving token metadata:", error);
      }
    };
    handlercontract();
  }

  const handlerimportbtn1 = () => {
    setName(importtokensymbol);
    setPath(
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfa2_GM1L3Bix0F0DS_7obaq566qP-kViUWA&usqp=CAU"
    );
    setTokenaddress1(inputvalueaddress);
    console.log("address import token -->" + inputvalueaddress);
  };
  const handlerimportbtn2 = () => {
    setName1(importtokensymbol);
    setPath1(
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfa2_GM1L3Bix0F0DS_7obaq566qP-kViUWA&usqp=CAU"
    );
    setTokenaddress2(inputvalueaddress);
    console.log("address import token2 -->" + inputvalueaddress);
  };

  //useEffect for get pairaddress  byapi
  useEffect(() => {
    var pairaddressdata;

    const pairaddressfun = async () => {
      const buyToken = "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"; //cake
      // 0xC95Eec280A392820e9bc332b71FDd35D646F5302 // Replace with the actual buy token addressv  //second
      const sellToken = "0xC95Eec280A392820e9bc332b71FDd35D646F5302"; // Replace with the actual sell token address //first
      //0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82
      const sellAmount = "1000000000000"; // Replace with the actual sell amount
      console.log("called aa function");
      await fetch(
        `https://bsc.api.0x.org/swap/v1/quote?buyToken=${tokenaddresss2}&sellToken=${tokenaddresss1}&sellAmount=${sellAmount}`
      )
        .then((response) => response.json())
        .then(
          (data) => (
            // console.log(data.orders[0].fillData.tokenAddressPath))
            // setPairaddressdata(data.orders[0].fillData.tokenAddressPath),
            console.log(
              "pair address path from api:",
              data.orders[0].fillData.tokenAddressPath
            ),
            (pairaddressdata = [...data.orders[0].fillData.tokenAddressPath]),
            console.log(" pair address inside", pairaddressdata)
          )
        )
        .catch((error) => console.log("error in pair address api", error));
      ///---------------------------------------------------------------------------------
      if (pairaddressdata.length > 0) {
        if (pairaddressdata.length == 2) {
          const pairPath = [pairaddressdata[0], pairaddressdata[1]];

          setPathpair([pairaddressdata[0], pairaddressdata[1]]);

          console.log("pairpath2  " + pairPath);
          console.log("pairaddressdata1 :" + pairaddressdata[0]);
          console.log("pairaddressdata2 :" + pairaddressdata[1]);
        } else if (pairaddressdata.length == 3) {
          const pairPath = [
            pairaddressdata[0],
            pairaddressdata[1],
            pairaddressdata[2],
          ];
          setPathpair([
            pairaddressdata[0],
            pairaddressdata[1],
            pairaddressdata[2],
          ]);

          console.log("pairpath3  " + pairPath);
          console.log("pairaddressdata1 :" + pairaddressdata[0]);
          console.log("pairaddressdata2 :" + pairaddressdata[1]);
          console.log("pairaddressdata3 :" + pairaddressdata[2]);
        } else {
          const pairPath = [
            pairaddressdata[0],
            pairaddressdata[1],
            pairaddressdata[2],
            pairaddressdata[3],
          ];
          setPathpair([
            pairaddressdata[0],
            pairaddressdata[1],
            pairaddressdata[2],
            pairaddressdata[3],
          ]);

          console.log("pairpath4  " + pairPath);
          console.log("pairaddressdata1 :" + pairaddressdata[0]);
          console.log("pairaddressdata2 :" + pairaddressdata[1]);
          console.log("pairaddressdata3 :" + pairaddressdata[2]);
          console.log("pairaddressdata3 :" + pairaddressdata[3]);
        }

        //----------------------------------------------------------------------
      }
    };

    pairaddressfun();
  }, [tokenaddresss1, tokenaddresss2]);
  console.log("final path address ", pathpair);

  return (
    <>
      <BrowserRouter>
        <Menu
          handlerconnectmetamask={handlerconnectmetamask}
          account={account}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                enteramountbtn={enteramountbtn}
                handlerset={handlerset}
                handlerset1={handlerset1}
                handlerswap={handlerswap}
                arrowswap={arrowswap}
                pricearrowswap={pricearrowswap}
                name={name}
                name1={name1}
                pricetokenname1={pricetokenname1}
                pricetokenname2={pricetokenname2}
                historycurrencyname={historycurrencyname}
                historycurrencypath={historycurrencypath}
                path={path}
                path1={path1}
                //for input field
                inputvaluechange1={inputvaluechange1}
                inputvaluechange2={inputvaluechange2}
                inputvalue1={inputvalue1}
                inputvalue2={inputvalue2}
                priceinput1={priceinput1}
                priceinput2={priceinput2}
                minimumreceive={minimumreceive}
                minimumreceivename={minimumreceivename}
                minimumReceiveLastTokenName={minimumReceiveLastTokenName}
                usd_value2={usd_value2}
                usd_value1={usd_value1}
                swapbtn={swapbtn}
                data={filteredData}
                coingeckapi={coingeckapi}
                pancakeswapapi={pancakeswapapi}
                coinmarketapi={coinmarketapi}
                handleSearch={handleSearch}
                importtokenname={importtokenname}
                importtokensymbol={importtokensymbol}
                handlerimportbtn1={handlerimportbtn1}
                handlerimportbtn2={handlerimportbtn2}
                inputvalueaddress={inputvalueaddress}
                // dynamicpairapi={dynamicpairapi}
              />
            }
          ></Route>
          <Route path="earn" element={<Earn />}></Route>

          <Route path="dashboard" element={<Dashboard />}></Route>

          <Route path="stake" element={<Stake />}></Route>

          <Route path="liquidity" element={<Liquidity />}></Route>

          <Route
            path="addliquidity"
            element={
              <Addliquidity
                handlerset={handlerset}
                handlerset1={handlerset1}
                name={name}
                name1={name1}
                gettokenuser={gettokenuser}
                path={path}
                path1={path1}
                currencyname={currencyname}
                inputvaluechange1={inputvaluechange1}
                inputvaluechange2={inputvaluechange2}
                priceinput1={priceinput1}
                priceinput2={priceinput2}
                inputvalue1={inputvalue1}
                inputvalue2={inputvalue2}
                usd_value2={usd_value2}
                usd_value1={usd_value1}
                data={filteredData}
                coingeckapi={coingeckapi}
                pancakeswapapi={pancakeswapapi}
                coinmarketapi={coinmarketapi}
                handleSearch={handleSearch}
              />
            }
          ></Route>
          <Route path="newstate" element={<NewState />}></Route>
          <Route path="pool" element={<Pool />}></Route>
          <Route path="*" element={<p>Path not resolved</p>} />
        </Routes>

        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
