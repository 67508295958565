import { render } from "@testing-library/react";
import React from "react";
import { Outlet, Link } from "react-router-dom";

function DataTable(){
  return(
<>
<table className="display table w-100 my-4" >
   {/* <thead>
      <tr>
         <th>Supercharger</th>
         <th>Network</th>
         <th>Earned</th>
         <th>Apr</th>
         <th>My Desposits</th>
         <th>Wallet Balance</th>
         <th></th>
      </tr>
   </thead> */}
   <tbody>
      <tr>
         <td>
            <div className="eth_icon">
               <img src="img/icon/etherium.png" alt="etherium"  className="img-fluid" />
               <div className="eth_text text-start">
                  <h4>BNB-BUSD LP</h4>
               </div>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <a href="javascript:void(0);">0.25%</a>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Earned</span>
               <h4>0</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>APR</span>
               <h4>15.79%</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Staked Liquidity</span>
               <h4>$30,759,343</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Multiplier</span>
               <h4>160x</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Available</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <span>Staked</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="caret" id="accordionExample">
                <h5 id="headingOne">
                <i class="fa fa-caret-down" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i>
                </h5>
            </div>
         </td>
      </tr>


      <tr>
        <td colSpan="9">
         <div class="accordion mx-4" id="accordionExample">
            <div class="accordion-item border-0">
               
               <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                     <div className="row align-items-center">
                        <div className="col-md-3">
                           <div className="t_list">
                              <ul>
                                 <li>
                                    <p>Add Bidex-BNB LP</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>See Pair Info</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>View Contract</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-md-9">
                           <div className="start_farming">
                              <p>START FARMING</p>
                              <Link to="/liquidity" className="btn btn-primary w-100 py-3 bg-btn">Connect Wallet</Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </td>
      </tr>
     
      {/* <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </div>
        </div> */}

<tr>
         <td>
            <div className="eth_icon">
               <img src="img/76f003a.png" alt="etherium"  className="img-fluid" />
               <div className="eth_text text-start">
                  <h4>BNB-BUSD LP</h4>
               </div>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <a href="javascript:void(0);">0.25%</a>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Earned</span>
               <h4>0</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>APR</span>
               <h4>15.79%</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Staked Liquidity</span>
               <h4>$30,759,343</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Multiplier</span>
               <h4>160x</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Available</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <span>Staked</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="caret" id="accordionExample">
                <h5 id="headingTwo">
                <i class="fa fa-caret-down" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"></i>
                </h5>
            </div>
         </td>
         
      </tr>

      <tr>
        <td colSpan="9">
         <div class="accordion mx-4" id="accordionExample">
            <div class="accordion-item border-0">
               
               <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                     <div className="row align-items-center">
                        <div className="col-md-3">
                           <div className="t_list">
                              <ul>
                                 <li>
                                    <p>Add Bidex-BNB LP</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>See Pair Info</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>View Contract</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-md-9">
                           <div className="start_farming">
                              <p>START FARMING</p>
                              <Link  to="/liquidity" className="btn btn-primary w-100 py-3 bg-btn">Connect Wallet</Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </td>
      </tr>


     
<tr>
         <td>
            <div className="eth_icon">
               <img src="img/bnb.png" alt="etherium"  className="img-fluid" />
               <div className="eth_text text-start">
                  <h4>BNB-BUSD LP</h4>
               </div>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <a href="javascript:void(0);">0.25%</a>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Earned</span>
               <h4>0</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>APR</span>
               <h4>15.79%</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Staked Liquidity</span>
               <h4>$30,759,343</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Multiplier</span>
               <h4>160x</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Available</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <span>Staked</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="caret" id="accordionExample">
                <h5 id="headingThree">
                <i class="fa fa-caret-down" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"></i>
                </h5>
            </div>
         </td>
         
      </tr>
      <tr>
        <td colSpan="9">
         <div class="accordion mx-4" id="accordionExample">
            <div class="accordion-item border-0">
               
               <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                     <div className="row align-items-center">
                        <div className="col-md-3">
                           <div className="t_list">
                              <ul>
                                 <li>
                                    <p>Add Bidex-BNB LP</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>See Pair Info</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>View Contract</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-md-9">
                           <div className="start_farming">
                              <p>START FARMING</p>
                              <Link  to="/liquidity" className="btn btn-primary w-100 py-3 bg-btn">Connect Wallet</Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </td>
      </tr>
<tr>
         <td>
            <div className="eth_icon">
               <img src="img/0beb238.png" alt="etherium"  className="img-fluid" />
               <div className="eth_text text-start">
                  <h4>BNB-BUSD LP</h4>
               </div>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <a href="javascript:void(0);">0.25%</a>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Earned</span>
               <h4>0</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>APR</span>
               <h4>15.79%</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Staked Liquidity</span>
               <h4>$30,759,343</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Multiplier</span>
               <h4>160x</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
            <span>Available</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="eth_text">
               <span>Staked</span>
               <h4>O LP</h4>
            </div>
         </td>
         <td>
            <div className="caret" id="accordionExample">
                <h5 id="headingFour">
                <i class="fa fa-caret-down" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour"></i>
                </h5>
            </div>
         </td>
         
      </tr>

      <tr>
        <td colSpan="9">
         <div class="accordion mx-4" id="accordionExample">
            <div class="accordion-item border-0">
               
               <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                     <div className="row align-items-center">
                        <div className="col-md-3">
                           <div className="t_list">
                              <ul>
                                 <li>
                                    <p>Add Bidex-BNB LP</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>See Pair Info</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                                 <li>
                                    <p>View Contract</p>
                                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-md-9">
                           <div className="start_farming">
                              <p>START FARMING</p>
                              <Link to="/liquidity" className="btn btn-primary w-100 py-3 bg-btn">Connect Wallet</Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </td>
      </tr>
   </tbody>
</table>
</>
);
}
export default DataTable;