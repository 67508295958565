import React from "react";
import { ReactDOM } from "react";
import DataTable from '../DataTable/DataTable';
function Earn(){
return(
<>
<div className="main_box">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <h1 className="main-heading">Your hassle-free DeFi yield finder</h1>
         </div>
         <div className="col-md-12">
            <div className="card">
               <div className="card-body">
                  <div className="table_heading">
                     <div className="row">
                        <div className="col-md-5">
                           <div className="d-flex ">
                              <h5 className="card-title">Total Value Locked</h5>
                              <h5 className="card-title ms-3">$7,738,350</h5>
                           </div>
                        </div>
                        <div className="col-md-7">
                           <div className="currancy_list">
                              <div className="row">
                                 <div className="col-md-2">
                                    <div className="oplist">
                                       <img src="img/76f003a.png" alt="op" className="img-fluid" />
                                       <h6>$575.18K</h6>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                    <div className="oplist">
                                       <img src="img/bnb.png" alt="bnb" className="img-fluid" />
                                       <h6>$1.34M</h6>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                    <div className="oplist">
                                       <img src="img/0beb238.png" alt="0beb238" className="img-fluid" />
                                       <h6>$605.14K</h6>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                    <div className="oplist">
                                       <img src="img/cbd0b85.png" alt="cbd0b85" className="img-fluid" />
                                       <h6>$1.10M</h6>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                    <div className="oplist">
                                       <img src="img/bbc9ede.png" alt="bbc9ede" className="img-fluid" />
                                       <h6>$2.34M</h6>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                    <div className="oplist">
                                       <img src="img/727b33d.png" alt="727b33d" className="img-fluid" />
                                       <h6>$1.75M</h6>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="table_heading mt-5">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="d-flex">
                              <h5 className="card-title">Featured vaults</h5>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div  className="item" style={{background: "linear-gradient(315deg, rgb(37, 42, 54) 0%, rgb(46, 42, 29) 100%)"}}>
                           <div  className="linea flex-all">
                              <div  className="name">BTC.b</div>
                              <div className="chain flex-align">
                                 <img src="/img/0beb238.png" alt="Avalanche" /><span>Avalanche</span>
                              </div>
                           </div>
                           <div className="lineb">7.98% APR</div>
                           <img src="/img/d28fd6b.png" className="coin" />
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div  className="item" style={{background: "linear-gradient(315deg, rgb(37, 42, 54) 0%, rgb(46, 42, 29) 100%)"}}>
                        <div  className="linea flex-all">
                           <div  className="name">FTM</div>
                           <div  className="chain flex-align"><img  src="/img/cbd0b85.png" alt="Fantom" /><span >Fantom</span></div>
                        </div>
                        <div  className="lineb">11.91% APR</div>
                        <img  src="/img/7ab8a8b.png" className="coin" alt="coin" />
                     </div>
                  </div>
                  <div className="col-md-3">
                     <div  className="item" style={{background: "linear-gradient(315deg, rgb(37, 42, 54) 0%, rgb(46, 42, 29) 100%)"}}>
                     <div  className="linea flex-all">
                        <div  className="name">BTC.b</div>
                        <div className="chain flex-align">
                           <img src="/img/727b33d.png" alt="Avalanche" /><span>Avalanche</span>
                        </div>
                     </div>
                     <div className="lineb">7.98% APR</div>
                     <img src="/img/b8e0cc7.png" alt="coin" className="coin" />
                  </div>
               </div>
               <div className="col-md-3">
                  <div  className="item" style={{background: "linear-gradient(315deg, rgb(37, 42, 54) 0%, rgb(46, 42, 29) 100%)"}}>
                  <div  className="linea flex-all">
                     <div  className="name">BTC.b</div>
                     <div className="chain flex-align">
                        <img src="/img/76f003a.png" alt="Avalanche" /><span>Avalanche</span>
                     </div>
                  </div>
                  <div className="lineb">7.98% APR</div>
                  <img src="/img/2835f83.png" className="coin" alt="coin" />
               </div>
            </div>
         </div>
      </div>
      <div className="table_heading mt-5">
         <div className="row">
            <div className="col-md-12">
               <div className="d-flex">
                  <h5 className="card-title">My Earnings</h5>
               </div>
            </div>
            <div className="col-md-4">
               <div  className="item1">
                  <div  className="line-a">Holdings</div>
                  <div  className="line-b flex-all">
                     <div  className="left">-</div>
                     <div  className="right">- assets</div>
                  </div>
               </div>
            </div>
            <div className="col-md-4">
               <div  className="item1">
                  <div  className="line-a">Monthly Profits</div>
                  <div  className="line-b flex-all">
                     <div  className="left">-</div>
                     <div  className="right">- Daily</div>
                  </div>
               </div>
            </div>
            <div className="col-md-4">
               <div  className="item1">
                  <div  className="line-a">Average APR</div>
                  <div  className="line-b flex-all">
                     <div  className="left">-</div>
                     <div  className="right">- Daily</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="data_table"></div>
      <div className="row align-items-center">
         <div className="col-md-5">
            <ul className="nav nav-tabs border-0 py-5" id="myTab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><i class="fa fa-th" aria-hidden="true"></i></button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Others</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">My vaults</button>
               </li>
            </ul>
         </div>
         <div className="col-md-7">
            <div className="row align-items-center">
               <div className="col-md-5">
                  <div className="search-box">
                     <input type="text" name="" className="form-control" placeholder='Try "USDT" ' />
                     <i className="fa fa-search"></i>
                  </div>
               </div>
               <div className="col-md-4">
                  <select name="" className="new_drop">
                     <option value="1">All Chains</option>
                     <option value="2">Two</option>
                     <option value="3">Three</option>
                     <option value="4">Four</option>
                  </select>
               </div>
               <div className="col-md-3">
                  <div className="form-check form-switch float-end">
                     <label className="form-check-label text-white">Staked Only</label>
                     <input className="form-check-input" type="checkbox" role="switch"  />
                  </div>
               </div>
            </div>
         </div>
         <div className="col-md-12">
            <div className="tab-content" id="myTabContent">
               <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <DataTable />
               </div>
               <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <DataTable />
               </div>
               <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <DataTable />
               </div>
            </div>
         </div>
         <div>
            <div className="form-check form-switch float-end p-3">
               <label className="form-check-label text-white">Hide zero balances</label>
               <input className="form-check-input" type="checkbox" role="switch"  />
            </div>
         </div>
      </div>
   </div>
</div>
</div>
</div>
</div>
</div>
</>
);
}
export default Earn;