import React from "react";
import DataTable from "../DataTable/DataTable";

function Dashboard(){
    return(
        <>

            <div className="my-5">
            <div className="container">
                <div className="col-md-9 offset-md-1">
                <div className="dashboard_volume">
                    <div className="row">
                        <div className="col-md-4">
                            <div data-v-c4375d92="" class="total-item"><div data-v-c4375d92="" class="text">24-hr Trading Volume</div><div data-v-c4375d92="" class="num">$19,953,983</div></div>
                        </div>
                        <div className="col-md-4">
                            <div data-v-c4375d92="" class="total-item"><div data-v-c4375d92="" class="text">Total Volume</div><div data-v-c4375d92="" class="num">$3,562,613,785</div></div>
                        </div>
                        <div className="col-md-4">
                            <div data-v-c4375d92="" class="total-item border-0"><div data-v-c4375d92="" class="text">Total Bought Back</div><div data-v-c4375d92="" class="num">$3,645,369 WOO</div></div>
                        </div>
                    </div>
                </div>


                <div className="row align-items-center">
                    <div className="col-md-12">
                    <ul className="nav nav-tabs border-0 pt-5 pb-0 justify-content-center stakeul" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                <img src="img/0beb238.png" alt="Avalanche" /> Avalanche
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                            <img src="img/bnb.png" alt="BNB Chain" />BNB Chain
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-fantom" data-bs-toggle="tab" data-bs-target="#fantom" type="button" role="tab" aria-controls="fantom" aria-selected="false">
                            <img src="img/cbd0b85.png" alt="Fantom" />  Fantom
                            </button>
                        </li>

                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Polygon-tab" data-bs-toggle="tab" data-bs-target="#Polygon" type="button" role="tab" aria-controls="Polygon" aria-selected="false">
                            <img src="img/bbc9ede.png" alt="Polygon" />Polygon
                            </button>
                        </li>

                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="arbitrum-tab" data-bs-toggle="tab" data-bs-target="#arbitrum" type="button" role="tab" aria-controls="arbitrum" aria-selected="false">
                            <img src="img/727b33d.png" alt="Arbitrum" /> Arbitrum
                            </button>
                        </li>

                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="optimism-tab" data-bs-toggle="tab" data-bs-target="#optimism" type="button" role="tab" aria-controls="optimism" aria-selected="false">
                            <img src="img/76f003a.png" alt="Optimism" />Optimism
                            </button>
                        </li>
                        
                        </ul>
                        
                    </div>
                    </div>

                    <div className="col-md-12">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active mt-4 stake_active" id="home" role="tabpanel" aria-labelledby="home-tab" >
                            <div className="row align-items-center">
                                <div className="card">
                                    <div className="cardbody">
                                        <DataTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="card">
                                <div className="cardbody">
                                    <DataTable />
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="fantom" role="tabpanel" aria-labelledby="fantom-tab">
                            <div className="card">
                                <div className="cardbody">
                                    <DataTable />
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="Polygon" role="tabpanel" aria-labelledby="Polygon-tab">
                            <div className="card">
                                <div className="cardbody">
                                    <DataTable />
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="arbitrum" role="tabpanel" aria-labelledby="arbitrum-tab">
                            <div className="card">
                                <div className="cardbody">
                                    <DataTable />
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="optimism" role="optimism" aria-labelledby="optimism-tab">
                            <div className="card">
                                <div className="cardbody">
                                    <DataTable />
                                </div>
                            </div>
                        </div>
                        </div>
                         </div>

                    
                 </div>
               </div>
            </div>
        </>
    );
}

export default Dashboard;