import React from "react";
// import listarray from "./LIstArray";

function Addliquidity(props) {
 

  return (
    <>
      <div className="container">
        <div className="col-md-8 offset-md-2">
          <div className="card p-0">
            <div className="card-body p-0">
              <div className="border-bottom p-3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center text-white">
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                      <h6 className="ms-3  ">Add V3 Liquidity</h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="apr float-end text-white">
                      {/* 
                        <p>APR</p>
                        */}
                      <ul className="ms-0 list-unstyled d-flex">
                        {/* 
                           <li>0%</li>
                           <li><i className="fa fa-calculator" aria-hidden="true"></i></li>
                           <li><i className="fa fa-question-circle-o" aria-hidden="true"></i></li>
                           */}
                        <li>
                          <i
                            className="fa fa-cog"
                            aria-hidden="true"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop3"
                          ></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="row">
                  <div className="col-md-6">
                    <div className="deposit_bx">
                      <h6>CHOOSE TOKEN PAIR</h6>
                      <div className="row align-items-center my-4">
                        <div className="col-md-5">
                          <div
                            className="c_t_p"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            <a href="javascript:void(0);">
                              <img
                                src={props.path}
                                alt="bnb"
                                className="img-fluid"
                              />
                              {props.name}
                            </a>
                            <i className="fa fa-sort-desc" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="c_lus text-center">
                            <h4>+</h4>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div
                            className="c_t_p"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop5"
                          >
                            <a href="javascript:void(0);">
                              <img
                                src={props.path1}
                                alt="bnb"
                                className="img-fluid"
                              />
                              {props.name1}
                            </a>
                            <i className="fa fa-sort-desc" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                      <h6>DEPOST AMOUNT</h6>
                      <div className="d-flex deposit-img align-items-center mt-4">
                        <img src={props.path} alt="bnb" className="img-fluid" />
                        <h6 className="ms-2">{props.name}</h6>
                      </div>
                      <form action="">
                        <div className="form-group mt-2 deposit_a">
                          <input
                            name="text"
                            id=""
                            className="form-control "
                         
                            placeholder={props.inputvalue1}
                            value={props.inputvalue1}
                            onChange={props.inputvaluechange1}
                            
                          />
                          <p>USD {props.usd_value1}</p>
                        </div>
                      </form>
                      <div className="d-flex deposit-img align-items-center mt-4">
                        <img
                          src={props.path1}
                          alt="bnb"
                          className="img-fluid"
                        />
                        <h6 className="ms-2">{props.currencyname}</h6>
                      </div>
                      <form action="">
                        <div className="form-group mt-2 deposit_a">
                          <input
                            name="text"
                            id=""
                            className="form-control "
                            placeholder={props.inputvalue2}
                            onChange={props.inputvaluechange2}
                          />
                          <p>USD {props.usd_value2}</p>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="transform_position">
                      <div className="center_transform">
                        <img
                          src="img/ed5f1a1.png"
                          alt=""
                          className="img-fluid"
                        />
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          data-bs-toggle="modal"
              data-bs-target="#staticBackdrop4"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
       tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
       <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Select a Token
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            

            <div className="modal-body ">
              <div className="mb-3 token_model">
              <input type="text" placeholder="Search" className="form-control" onChange={props.handleSearch} />
              </div>
              <h6 className="card-title font-700">
                Common bases
                <img
                  src="img/question.png"
                  alt="question"
                  className="img-fluid ms-2 qu"
                />
              </h6>
              <div className="swap_modal">
                <ul className="d-flex ms-0 align-items-center list-unstyled mt-3 comon_b">
                  <li>
                    
                     {props.historycurrencyname ? <>

                    <div data-bs-dismiss="modal" aria-label="Close">

                     <img src={props.historycurrencypath} alt="bnb" /> {props.historycurrencyname} 

                    </div>
                     </> :null}
                  </li>
                </ul>
              </div>
              <div className="v_list">
                <ul>
                  {props.data.map((item1, index1) => (
                    <>
                      <li
                        key={item1.name}
                        onClick={() => props.handlerset(item1)}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <img 
                          src={item1.logoURI}
                          alt="bnb"
                          className="img-fluid"
                        />
                        <div>
                          <h6  >{item1.symbol}</h6>
                          <p    >{item1.name}</p>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button type="button" className="btn btn-primary w-100" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop6">
              {/* onClick={props.coingeckapi} */}
                Manage Tokens
              </button>
            </div>
          </div>
        </div>
      </div>

     

     


      <div
        className="modal fade"
        id="staticBackdrop5"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
       tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Select a Token
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

              
            </div>
           

            <div className="modal-body ">
              <div className="mb-3 token_model">
              <input type="text" placeholder="Search" className="form-control" onChange={props.handleSearch} />
              </div>
              
              <h6 className="card-title font-700">
                Common bases
                <img
                  src="img/question.png"
                  alt="question"
                  className="img-fluid ms-2 qu"
                />
              </h6>
              <div className="swap_modal">
                <ul className="d-flex ms-0 align-items-center list-unstyled mt-3 comon_b">
                  <li>
                  {props.historycurrencyname ? <>

<div data-bs-dismiss="modal" aria-label="Close">

 <img src={props.historycurrencypath} alt="bnb" /> {props.historycurrencyname} 

</div>
 </> :null}
                  </li>
                  {/* <li>
                    <div>
                      <img src="/img/bnb.png" alt="bnb" /> USDT
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="/img/bnb.png" alt="bnb" /> BTCB
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="/img/bnb.png" alt="bnb" /> ACH
                    </div>
                  </li> */}
                </ul>
              </div>
              <div className="v_list">
                <ul>
                  {props.data.map((item1, index1) => (
                    <>
                      <li
                        key={index1}
                        onClick={() => props.handlerset1(item1)}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <img  
                          src={item1.logoURI}
                          alt="bnb"
                          className="img-fluid"
                        />
                        <div>
                          <h6  >{item1.symbol}</h6>
                          <p   >{item1.name}</p>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button type="button" className="btn btn-primary w-100"  data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop6"
              >
                Manage Tokens
              </button>
            </div>
          </div>
        </div>
      </div>


      <div
        className="modal fade"
        id="staticBackdrop4"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
       tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                You will receive
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <div className="card_heading bnb_chain_i  my-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img src={props.path} className="img-fluid" alt="bnb" />
                    <img
                      src={props.path1}
                      className="img-fluid"
                      alt="bnb"
                    />
                    <h5 className="card-title">{props.name}-{props.name1} LP</h5>
                  </div>

                  <div className="swap_box_icon">
                    <h5 className="card-title">0.0202928</h5>
                  </div>
                </div>
              </div>

              <div className="card_heading d-flex justify-content-between align-items-center my-4">
                <h5 className="card-title">Your Share In The Pair </h5>
                <div className="swap_box_icon">
                  <h5 className="card-title">0.0009644 %</h5>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  
                </div>
                <div className="col-md-9">
                  <table className="table table_liqui">
                    <tbody>
                      <tr>
                        <td>
                          <div className="confirm_box d-flex align-items-center">
                            <img src={props.path} className="img-fluid" />
                             <p>{props.name}</p>
                          </div>
                        </td>
                        <td>
                          <div className="liqui_input">
                            <p>{props.inputvalue1}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="confirm_box d-flex align-items-center">
                            <img src={props.path1} className="img-fluid" />
                            <p>{props.name1}</p>
                          </div>
                        </td>
                        <td>
                          <div className="liqui_input">
                            <p>{props.inputvalue2}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <table className="table table_liqui">
                <tbody>
                  <tr>
                    <td>
                      <div className="confirm_box d-flex align-items-center">
                        <img src="img/bnb.png" className="img-fluid" />
                        <p>RATES</p>
                      </div>
                    </td>
                    <td>
                      <div className="confirm_box">
                        <p>1 {props.name} = {props.priceinput1 }  {props.name1}</p>
                        <p>1 {props.name1} = {props.priceinput2 } {props.name}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="card_heading my-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="card-title">Slippage Tolerance</h5>
                  <div className="swap_box_icon">
                    <h5 className="card-title">0.5%</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button type="button" className="btn btn-primary w-100">
                Confirm Supply
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div
        className="modal fade"
        id="staticBackdrop6"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
       tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
              Manage
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            

            <div className="modal-body ">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">List</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Tokens</button>
            </li>
          </ul>
          <div className="tab-content mt-4" id="myTabContent">
            <div className="tab-pane fade show active mt-2 px-3 border-radius-0" style={{borderRadius:"15px", padding:"10px"}} id="home" role="tabpanel" aria-labelledby="home-tab">
          <div className="token_model"><input type="text" placeholder="https:// or ipfs://" className="form-control" /></div>

            <div>
                <table className="table table_liqui liqui2">
                  <tbody>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="manage_box d-flex align-items-center">
                          <img src="img/7186.png" className="img-fluid" alt="bnb" />
                          <div className="text-start">
                            <h6>PancakeSwap Extended</h6>                           <span>338 Tokens</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                            <input type="checkbox"  onClick={props.pancakeswapapi}/>
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="manage_box d-flex align-items-center">
                          <img src="img/coingecko.png" className="img-fluid" alt="bnb" />
                          <div className="text-start">
                            <h6>CoinGecko</h6>
                            <span>3999 Tokens</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                            <input type="checkbox"  onClick={props.coingeckapi}/>
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="manage_box d-flex align-items-center">
                          <img src="img/marketcoin.png" className="img-fluid" alt="bnb" />
                          <div className="text-start">
                            <h6>CoinMarket Cap</h6>
                            <span>7924 Tokens</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                          <input type="checkbox"  onClick={props.coinmarketapi}/>
                          
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="manage_box d-flex align-items-center">
                          <img src="img/7186.png" className="img-fluid" alt="bnb" />
                          <div className="text-start">
                            <h6>PancakeSwap BNB Chain MM</h6>
                            <span>6 Tokens</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                          <input type="checkbox" />
                          
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab" style={{borderRadius:"15px", padding:"20px 15px"}}>
            <div className="token_model" >
              <input type="text" placeholder="https:// or ipfs://" className="form-control" />
              <h5 className="mt-3">0 Imported Tokens</h5>
            </div>
            </div>
          </div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
}
export default Addliquidity;
