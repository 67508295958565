import React from "react";
// import { ReactDOM } from "react";
import DataTable from '../DataTable/DataTable';
function Pool(){
return(
<>
<div className="main_box">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <h1 className="main-heading">Syrup Pools</h1>
         </div>
         <div className="col-md-12">
            <div className="card">
               <div className="card-body">
      <div className="data_table"></div>
      <div className="row align-items-center">
         <div className="col-md-5">
            <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><i className="fa fa-th" aria-hidden="true"></i></button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Others</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">My vaults</button>
               </li>
            </ul>
         </div>
         <div className="col-md-7">
            <div className="row align-items-center">
               <div className="col-md-5">
                  <div className="search-box">
                     <input type="text" name="" className="form-control" placeholder='Try "USDT" ' />
                     <i className="fa fa-search"></i>
                  </div>
               </div>
               <div className="col-md-4">
                  <select name="" className="new_drop">
                     <option value="1">All Chains</option>
                     <option value="2">Two</option>
                     <option value="3">Three</option>
                     <option value="4">Four</option>
                  </select>
               </div>
               <div className="col-md-3">
                  <div className="form-check form-switch float-end">
                     <label className="form-check-label text-white">Staked Only</label>
                     <input className="form-check-input" type="checkbox" role="switch"  />
                  </div>
               </div>
            </div>
         </div>
         <div className="col-md-12">
            <div className="tab-content" id="myTabContent">
               <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <DataTable />
               </div>
               <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <DataTable />
               </div>
               <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <DataTable />
               </div>
            </div>
         </div>
         <div>
            <div className="form-check form-switch float-end p-3">
               <label className="form-check-label text-white">Hide zero balances</label>
               <input className="form-check-input" type="checkbox" role="switch"  />
            </div>
         </div>
      </div>
   </div>
</div>
</div>
</div>
</div>
</div>
</>
);
}
export default Pool;