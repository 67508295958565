import React from "react";
import {  Link } from "react-router-dom";
function Menu(props) {
return (
<>
<div className="top_nav">
   <div className="container-fluid">
      <div className="row">
         <div className="col-md-1 col-4">
            <div className="logo d-flex align-items-center h-100">
               {/* <Link to="/"><img src="img/assetsswap.png" className="img-fluid" /></Link> */}
               <Link to="/"><img src="img/logo.png" className="img-fluid" /></Link>
          
          </div>
         </div>
         <div className="col-md-6 col-8">
            <nav className="navbar navbar-expand-lg navbar-light ">
               <div className="container-fluid">
               <div className="menu_btn men_resp">
            <ul className="list-unstyled">
                  <li>
                     <button className="btn btn-secondary">
                     <img src="img/bnb.png" className="img-fluid" />
                     BNB Chain
                     </button>
                  </li>
               </ul>
            </div>

                  <button
                     className="navbar-toggler"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target="#navbarNavDropdown"
                     aria-controls="navbarNavDropdown"
                     aria-expanded="false"
                     aria-label="Toggle navigation"
                     >
                  <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                     className="collapse navbar-collapse"
                     id="navbarNavDropdown"
                     >
                     <ul className="navbar-nav">
                        {/* <li className="nav-item dropdown active">
                           <Link className="nav-link  dropdown-toggle" to="#">
                           <img src="img/trade.png" className="img-fluid" />
                           Trade
                           </Link>
                           
                           <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdownMenuLink"
                              >
                              <li>
                                 <Link className="dropdown-item"  to="/">
                                 Swap
                                 </Link>
                              </li>
                              <li>
                                 <Link className="dropdown-item" to="/liquidity">
                                 Liquidity
                                 </Link>
                              </li>
                           </ul>
                        </li> */}
                         <li className="nav-item active">
                           <Link  className="nav-link" to="/">
                           {/* <img src="img/stake.png" className="img-fluid" /> */}
                           Swap
                           </Link>
                        </li>
                        {/* <li className="nav-item dropdown">
                           <Link className="nav-link  dropdown-toggle" to="#">
                           <img src="img/trade.png" className="img-fluid" />
                           Earn
                           </Link>
                           <ul
                              className="dropdown-menu dropdown-menu2"
                              aria-labelledby="navbarDropdownMenuLink"
                              >
                              <li>
                                 <Link  className="dropdown-item" to="/earn">
                                 Farm
                                 </Link>
                              </li>
                              <li>
                                 <Link className="dropdown-item" to="/pool">
                                 Pool
                                 </Link>
                              </li>
                           </ul>
                        </li> */}
                        <li className="nav-item  active">
                           <Link  className="nav-link  " to="/stake">
                           {/* <img src="img/stake.png" className="img-fluid" /> */}
                           Stake
                           </Link>
                        </li>
                        {/* <li className="nav-item">
                           <Link className="nav-link" to="/dashboard">
                           <img src="img/dashboard.png" className="img-fluid" />
                           Dashboard
                           </Link>
                        </li> */}
                     </ul>
                  </div>
               </div>
            </nav>
         </div>
         <div className="col-md-5">
            <div className="menu_btn">
               <ul className="list-unstyled">
                  <li>
                     <button className="btn btn-secondary">
                     <img src="img/bnb.png" className="img-fluid" />
                     BNB Chain
                     </button>
                  </li>
                  <li>
                     <button className="btn btn-secondary">
                     <img src="img/ast.png" className="img-fluid" />
                     $0.225
                     </button>
                  </li>
                  <li>
                     <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal3"
                        className="btn btn-primary"
                        id="connect_w"
                        >
                     {props.account}
                     <img src="img/wallet.png" alt="wallet" className="img-fluid" />
                     </button>
                  </li>
                  <li>
                     <div className="dropdown dropdown_hide">
                        <button
                           className="btn btn-secondary dropdown-toggle"
                           type="button"
                           id="dropdownMenuButton1"
                           data-bs-toggle="dropdown"
                           aria-expanded="false"
                           >
                        <i className="fa fa-credit-card" aria-hidden="true"></i>{" "}
                        &nbsp; 0000
                        </button>
                        <ul
                           className="dropdown-menu"
                           aria-labelledby="dropdownMenuButton1"
                           >
                           <li>
                              <Link className="dropdown-item" to="#">
                              Action
                              </Link>
                           </li>
                           <li>
                              <Link className="dropdown-item" to="#">
                              Another action
                              </Link>
                           </li>
                           <li>
                              <Link className="dropdown-item" to="#">
                              Something else here
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </li>
               </ul>
               {/* 
               <ul className="narbar-nav">
                  c
                  <li className="nav-item dropdown btn btn-secondary">
                     <a className="nav-link  dropdown-toggle" href="#" >
                     <img src="img/trade.png" className="img-fluid" />0x...2438
                     </a>
                     <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a className="dropdown-item" href="/">Swap</a></li>
                        <li><a className="dropdown-item" href="/liquidity">Liquidity</a></li>
                     </ul>
                  </li>
               </ul>
               */}
            </div>
         </div>
      </div>
   </div>
</div>
{/* Modal */}

<div className="elementor-background-overlay"></div>

<div
   className="modal  fade"
   id="staticBackdrop1"
   data-bs-backdrop="static"
   data-bs-keyboard="false"
   tabIndex="-1"
   aria-labelledby="staticBackdropLabel"
   aria-hidden="true"
   >
   <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
         <div className="modal-header border-0">
            <h5
               className="modal-title text-white"
               data-bs-toggle="modal"
               data-bs-target="#exampleModal3"
               >
               Connect to a wallet
            </h5>
            <button
               type="button"
               className="btn-close text-white"
               data-bs-dismiss="modal"
               aria-label="Close"
               ></button>
         </div>
         <div className="modal-body ">
            <div className="connect_wallet">
               <div className="card-body p-3">
                  <div className="metamask d-flex align-items-center justify-content-between">
                     <h3>Metamask</h3>
                     <img src="img/ed5f1a1.png"  alt="metamask" />
                  </div>
               </div>
               <div className="card-body p-3">
                  <div className="metamask d-flex align-items-center justify-content-between">
                     <h3>WalletConnect</h3>
                     <img src="img/dde8768.png" alt="walletconnect" />
                  </div>
               </div>
               <div className="card-body p-3">
                  <div className="metamask d-flex align-items-center justify-content-between">
                     <h3>OKX Wallet</h3>
                     <img src="img/1a26ef7.png" alt="okx wallet" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div
   className="modal fade"
   id="exampleModal3"
   tabIndex="-1"
   aria-labelledby="exampleModalLabel"
   aria-hidden="true"
   >
   <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
         <div className="modal-body p-0">
            <div className="row">
               <div className="col-md-6">
                  <div className="wallet_box">
                     <h5 className="modal-title" id="staticBackdropLabel">
                        Connect Wallet
                     </h5>
                     <p className="text-start">
                        Start by connecting with one of the wallets below. Be sure
                        to store your private keys or seed phrase securely. Never
                        share them with anyone.
                     </p>
                     <div className="row">
                        <div className="col-md-4">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/metamask.png"
                                    alt="metamask"
                                    className="img-fluid"
                                    onClick={props.handlerconnectmetamask}
                                    />
                                 <p onClick={props.handlerconnectmetamask}>Metamask</p>
                              </div>
                           </a>
                        </div>
                        {/* <div className="col-md-4">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/binance.png"
                                    alt="binance"
                                    className="img-fluid"
                                    />
                                 <p>Binance Wallet</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/coinbase.png"
                                    alt="coinbase"
                                    className="img-fluid"
                                    />
                                 <p>Coinbase Wallet</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/trust.png"
                                    alt="trust"
                                    className="img-fluid"
                                    />
                                 <p>Trust Wallet</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/walletconnect.png"
                                    alt="walletconnect"
                                    className="img-fluid"
                                    />
                                 <p>WalletConnect</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4 read_more">
                           <div className="read_more text-center">
                              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                              <p>More</p>
                           </div>
                        </div>
                        <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/opera.png"
                                    alt="opera"
                                    className="img-fluid"
                                    />
                                 <p>Opera Wallet</p>
                              </div>
                           </a>
                        </div>
                        <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/brave.png"
                                    alt="brave"
                                    className="img-fluid"
                                    />
                                 <p>Brave Wallet</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/mathwallet.png"
                                    alt="mathwallet"
                                    className="img-fluid"
                                    />
                                 <p>MathWallet</p>
                              </div>
                           </a>
                        </div>
                        <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/tokenpocket.png"
                                    alt="tokenpocket"
                                    className="img-fluid"
                                    />
                                 <p>TokenPocket</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/safepal.png"
                                    alt="safepal"
                                    className="img-fluid"
                                    />
                                 <p>Safepal</p>
                              </div>
                           </a>
                        </div>
                        <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/coin98.png"
                                    alt="coin98"
                                    className="img-fluid"
                                    />
                                 <p>Coin98</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/blocto.png"
                                    alt="Blocto"
                                    className="img-fluid"
                                    />
                                 <p>Blocto</p>
                              </div>
                           </a>
                        </div> */}
                        {/* <div className="col-md-4 hide_more">
                           <a href="javascript:void(0);">
                              <div className="wallet_list">
                                 <img
                                    src="img/ledger.png"
                                    alt="ledger"
                                    className="img-fluid"
                                    />
                                 <p>Ledger</p>
                              </div>
                           </a>
                        </div> */}
                     </div>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="liqui_box">
                     <div className="liqui_img">
                        <h2>Haven’t got a wallet yet?</h2>
                        <img
                           src="https://cdn.pancakeswap.com/wallets/wallet_intro.png"
                           alt="wallet yet"
                           />
                        <br />
                        <a
                           href="javascript:void(0);"
                           className="btn btn-primary mt-3 px-4"
                           >
                        Learn How to Connect{" "}
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default Menu;