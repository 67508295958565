import React, { useState } from "react";
import Web3 from "web3";
// import {connectWeb3Metamask} from './functions'
import { connectWeb3 } from "../web3/Connection";
// import { updatawalletaddress } from "../Menu/Menu.js";
import { Outlet, Link } from "react-router-dom";

function Liquidity() {
 

  return (
    <>
      <div className="main_box">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading">
                Stake Assetswap to maximize your yields
              </h1>
            </div>
            <div className="offset-md-3 col-md-6">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <ul
                    className="nav nav-tabs border-0 pt-5 pb-0 justify-content-center stakeul"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <Link to="/" className="nav-link active" id="home-tab">
                        SWAP
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link to
                        ="/liquidity"
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        LIQUIDITY
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link  to="/earn" className="nav-link">
                        EARN
                      </Link>
                    </li>
                  </ul>
                  <div className="card">
                    <div className="card-body">
                      <div className="liquidiy">
                        <div className="d-flex justify-content-between">
                          <h3>Your Liquidity</h3>
                          <div className="liquidity_icon">
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop2"
                            >
                              <i className="fa fa-cog"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal2"
                            >
                              <i
                                className="fa fa-clock-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                        <p>Remove liquidity to receive tokens back</p>
                      </div>
                      <div className="para">
                        <p>Connect to a wallet to view your liquidity.</p>
                      </div>
                      <div className="wallet_btn">
                        <Link to="/addliquidity" className="btn btn-primary w-100 py-3 bg-btn">
                          + Add Liquidity
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Settings
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <div>
                <h4 className="bOHOqq">Swaps & Liquidity</h4>
                <div className="default_box d-flex align-items-center">
                  <p>Default Transaction Speed (GWEI)</p>
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </div>
                <ul
                  className="nav nav-tabs transaction border-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Default
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Standard
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Fast (6)
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Instant (7)
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <div className="default_box d-flex align-items-center">
                  <p>Slippage Tolerance</p>
                  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </div>
                <ul
                  className="nav nav-tabs transaction border-0"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      0.1%
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      0.5%
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      1.0%
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      0.50%
                    </button>
                  </li>
                </ul>
              </div>

              <div>
                <table className="table table_liqui">
                  <tbody>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="default_box d-flex align-items-center">
                          <p>Tx deadline (mins)</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="liqui_input">
                          <input type="text" className="iSRZGa" />
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="default_box d-flex align-items-center">
                          <p>Zap (Beta)</p>
                          <i
                            className="fa fa-question-circle-o"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                            <input type="checkbox" />
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="default_box d-flex align-items-center">
                          <p>Export Mode</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                            aria-hidden="true"
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                            <input type="checkbox" />
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="default_box d-flex align-items-center">
                          <p>Disable Multihops</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                            aria-hidden="true"
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                            <input type="checkbox" />
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="default_box d-flex align-items-center">
                          <p>Flippy sounds</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                            aria-hidden="true"
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2  m5">
                            <input type="checkbox" />
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>

                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="default_box d-flex align-items-center">
                          <p>Mm Linked Pool</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                            aria-hidden="true"
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                            <input type="checkbox" />
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>

                    <tr className="d-flex justify-content-between align-items-center">
                      <td>
                        <div className="default_box d-flex align-items-center">
                          <p>Use StableSwap by default</p>
                          <i
                            className="fa fa-question-circle-o"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your transaction will revert if it is left confirming for longer than this time."
                            aria-hidden="true"
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className="content1">
                          <label className="switchSmall2 m5">
                            <input type="checkbox" />
                            <small></small>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Settings
              </h5>
              <button
                type="button"
                className="btn-close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="wallet_btn">
                <button
                  className="btn btn-primary w-100 py-3 bg-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                >
                  Connect Wallet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </>
  );
}

export default Liquidity;
